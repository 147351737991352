// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    display: flex;
}

.placeholder-img {
    display: flex;
    width: 100%;
    height: 500px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: black;
    padding: 30px;
    opacity: 1 !important;
    filter: invert(1) grayscale(100);
}

.carousel-caption {
    display: flex;
    bottom: 11rem !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    max-width: 80%;
}

.carousel-title,
.carousel-text {
    display: flex !important;
    align-items: center !important;
}

.carousel-title {
    text-transform: uppercase;
    max-width: fit-content;
    margin-bottom: 0 !important;
    background-color: rgba(0, 0, 0, .5);
}

.carousel-text {
    font-size: large;
    height: 2.5rem;
    color: rgb(91, 91, 91);
    background-color: rgb(255, 255, 255)
}`, "",{"version":3,"sources":["webpack://./src/components/SlideCarousel/SlideCarousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,aAAa;AACjB;;AAEA;;IAEI,uBAAuB;IACvB,aAAa;IACb,qBAAqB;IACrB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,wBAAwB;IACxB,8BAA8B;IAC9B,kCAAkC;IAClC,sBAAsB;IACtB,cAAc;AAClB;;AAEA;;IAEI,wBAAwB;IACxB,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;IAC3B,mCAAmC;AACvC;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB;AACJ","sourcesContent":[".carousel-container {\n    display: flex;\n}\n\n.placeholder-img {\n    display: flex;\n    width: 100%;\n    height: 500px;\n}\n\n.carousel-control-prev-icon,\n.carousel-control-next-icon {\n    background-color: black;\n    padding: 30px;\n    opacity: 1 !important;\n    filter: invert(1) grayscale(100);\n}\n\n.carousel-caption {\n    display: flex;\n    bottom: 11rem !important;\n    align-items: center !important;\n    justify-content: center !important;\n    flex-direction: column;\n    max-width: 80%;\n}\n\n.carousel-title,\n.carousel-text {\n    display: flex !important;\n    align-items: center !important;\n}\n\n.carousel-title {\n    text-transform: uppercase;\n    max-width: fit-content;\n    margin-bottom: 0 !important;\n    background-color: rgba(0, 0, 0, .5);\n}\n\n.carousel-text {\n    font-size: large;\n    height: 2.5rem;\n    color: rgb(91, 91, 91);\n    background-color: rgb(255, 255, 255)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
