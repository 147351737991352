// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-box {
    width: 20em;
    padding: 50px;
    border: 1px solid rgba(212, 212, 212, 0.508);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.service-box h3,
p {
    line-height: 0.9;
}

.service-title {
    font-weight: bold;
    margin-bottom: 20px;

    &::after {
        content: "";
        position: absolute;
        width: 30px;
        margin-bottom: -8%;
        margin-top: 2.2rem;
        margin-left: -5.5rem;
        height: 1px;
        background: #c5c5c5;
    }
}

.service-box p {
    font-size: 14px;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceBox/ServiceBox.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,4CAA4C;IAC5C,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;;IAEnB;QACI,WAAW;QACX,kBAAkB;QAClB,WAAW;QACX,kBAAkB;QAClB,kBAAkB;QAClB,oBAAoB;QACpB,WAAW;QACX,mBAAmB;IACvB;AACJ;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".service-box {\n    width: 20em;\n    padding: 50px;\n    border: 1px solid rgba(212, 212, 212, 0.508);\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.service-box h3,\np {\n    line-height: 0.9;\n}\n\n.service-title {\n    font-weight: bold;\n    margin-bottom: 20px;\n\n    &::after {\n        content: \"\";\n        position: absolute;\n        width: 30px;\n        margin-bottom: -8%;\n        margin-top: 2.2rem;\n        margin-left: -5.5rem;\n        height: 1px;\n        background: #c5c5c5;\n    }\n}\n\n.service-box p {\n    font-size: 14px;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
