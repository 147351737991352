// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
    justify-content: center !important;
    height: 114px;
    border-top: 0px;
    overflow: hidden;
    border-top: 10px solid #555;
    gap: 70px;
}

.options {
    display: flex;
    gap: 40px;
    width: fit-content;
    white-space: nowrap;
}

.logo {
    scale: 0.7;
}

.nav {
    text-transform: uppercase;
    height: 100%;
}

.nav-link {
    height: 114px;
    display: flex !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    align-items: center !important; 
    &:hover {
        background-color: #d0d0d0d5;
    }
}

.number {
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/BasicNavbar/BasicNavbar.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,wBAAwB;IACxB,6BAA6B;IAC7B,8BAA8B;IAC9B,8BAA8B;IAC9B;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".navbar-container {\n    justify-content: center !important;\n    height: 114px;\n    border-top: 0px;\n    overflow: hidden;\n    border-top: 10px solid #555;\n    gap: 70px;\n}\n\n.options {\n    display: flex;\n    gap: 40px;\n    width: fit-content;\n    white-space: nowrap;\n}\n\n.logo {\n    scale: 0.7;\n}\n\n.nav {\n    text-transform: uppercase;\n    height: 100%;\n}\n\n.nav-link {\n    height: 114px;\n    display: flex !important;\n    padding-left: 15px !important;\n    padding-right: 15px !important;\n    align-items: center !important; \n    &:hover {\n        background-color: #d0d0d0d5;\n    }\n}\n\n.number {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
