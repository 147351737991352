// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners{
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.partner {
    flex: 1 1;
    padding: 15px 20px;
}

.title {
    text-align: center;
    color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/Partners/Partners.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".partners{\n    display: flex;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 30px;\n}\n\n.partner {\n    flex: 1;\n    padding: 15px 20px;\n}\n\n.title {\n    text-align: center;\n    color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
