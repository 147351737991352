// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #f9f9f9;
    color: #666;
    max-width: 20rem;
}

.test-box img {
    border-radius: 100px;
    border: 10px solid white;
    width: 120px;
}
.test-text {
    padding: 30px;
}

.test-text h6 {
    color: black;
}
.test-text p {
    line-height: 1.3;
    margin-bottom: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/TestimonialBox/TestimonialBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".test-box {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    text-align: center;\n    background-color: #f9f9f9;\n    color: #666;\n    max-width: 20rem;\n}\n\n.test-box img {\n    border-radius: 100px;\n    border: 10px solid white;\n    width: 120px;\n}\n.test-text {\n    padding: 30px;\n}\n\n.test-text h6 {\n    color: black;\n}\n.test-text p {\n    line-height: 1.3;\n    margin-bottom: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
