// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-scroll {
    display: flex;
    overflow: hidden;
    background-color: black;
}

.over-content {
    position: relative;
    margin-top: -160px;
    z-index: 10;
    max-width: 1000px;
    margin-left: 15%;
    margin-right: 15%;
    justify-content: center;
}

.over-content h1 {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-weight: bold;
}

.story {
    background: white;
    padding: 40px;
    color: gray;
}

.story p {
    line-height: 1.3;
}

.bg-container {
    width: 100%;
    margin-bottom: 5rem;
}

.container2 {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container2 p {
    color: gray;
    margin-top: 10px;
    line-height: 1.4 !important;
    font-size: 16px;
}

.container2 button {
    padding: 15px;
    width: 10rem;
    border: 2px solid rgba(178, 177, 177, 0.384);
    background-color: white;
    color: gray;
    text-transform: uppercase;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,4CAA4C;IAC5C,uBAAuB;IACvB,WAAW;IACX,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".bg-scroll {\n    display: flex;\n    overflow: hidden;\n    background-color: black;\n}\n\n.over-content {\n    position: relative;\n    margin-top: -160px;\n    z-index: 10;\n    max-width: 1000px;\n    margin-left: 15%;\n    margin-right: 15%;\n    justify-content: center;\n}\n\n.over-content h1 {\n    width: 100%;\n    justify-content: center;\n    text-align: center;\n    margin-bottom: 20px;\n    color: white;\n    font-weight: bold;\n}\n\n.story {\n    background: white;\n    padding: 40px;\n    color: gray;\n}\n\n.story p {\n    line-height: 1.3;\n}\n\n.bg-container {\n    width: 100%;\n    margin-bottom: 5rem;\n}\n\n.container2 {\n    padding: 3rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.container2 p {\n    color: gray;\n    margin-top: 10px;\n    line-height: 1.4 !important;\n    font-size: 16px;\n}\n\n.container2 button {\n    padding: 15px;\n    width: 10rem;\n    border: 2px solid rgba(178, 177, 177, 0.384);\n    background-color: white;\n    color: gray;\n    text-transform: uppercase;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
