// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tests-container {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    gap: 40px;
}

.test-title {
    text-align: center;
    margin-bottom: 5rem;
    color: #666;
}

.test-title h1 {
    font-weight: normal;
}

.tests {
    padding-bottom: 60px;
    border-bottom: 1px solid rgb(201, 201, 201);
}`, "",{"version":3,"sources":["webpack://./src/components/Testimonials/Testimonials.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,2CAA2C;AAC/C","sourcesContent":[".tests-container {\n    display: flex;\n    margin-left: 10%;\n    margin-right: 10%;\n    gap: 40px;\n}\n\n.test-title {\n    text-align: center;\n    margin-bottom: 5rem;\n    color: #666;\n}\n\n.test-title h1 {\n    font-weight: normal;\n}\n\n.tests {\n    padding-bottom: 60px;\n    border-bottom: 1px solid rgb(201, 201, 201);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
