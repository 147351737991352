// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-home {
    display: flex;
    flex-direction: row;
    margin: 5rem 0 5rem 0;
    width: 100%;
    max-width: 1000px;
    margin: 5rem auto;
}

.form-home-text {
    margin-right: 40px;
    width: 40%;
    display: flex;
    flex-direction: column;
}
.form-home-text h1 {
    margin-bottom: 10px;
}
.form-home-text p {
    line-height: 1.4;
    color: #666;
    font-size: 1rem;
}

.form-home label {
    width: 80%;
    font-weight: 600;
    margin-bottom: 15px;
}

.form-home input {
    float: left;
    width: 100%;
    background: #f1f1f1;
    border-bottom: 2px solid #e5e5e5;
    padding: 15px;
    border: 0px;
    border-bottom: 2px solid #e5e5e5;
}`, "",{"version":3,"sources":["webpack://./src/components/FormHome/FormHome.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,gCAAgC;IAChC,aAAa;IACb,WAAW;IACX,gCAAgC;AACpC","sourcesContent":[".form-home {\n    display: flex;\n    flex-direction: row;\n    margin: 5rem 0 5rem 0;\n    width: 100%;\n    max-width: 1000px;\n    margin: 5rem auto;\n}\n\n.form-home-text {\n    margin-right: 40px;\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n}\n.form-home-text h1 {\n    margin-bottom: 10px;\n}\n.form-home-text p {\n    line-height: 1.4;\n    color: #666;\n    font-size: 1rem;\n}\n\n.form-home label {\n    width: 80%;\n    font-weight: 600;\n    margin-bottom: 15px;\n}\n\n.form-home input {\n    float: left;\n    width: 100%;\n    background: #f1f1f1;\n    border-bottom: 2px solid #e5e5e5;\n    padding: 15px;\n    border: 0px;\n    border-bottom: 2px solid #e5e5e5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
