// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services {
    display: flex;
    gap: 30px;
}

.title {
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
}

.service-container button{
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 10px;
    border: 0;
    color: white;
    background: black;
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceContainer/ServiceContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".service-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.services {\n    display: flex;\n    gap: 30px;\n}\n\n.title {\n    font-weight: 300;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.service-container button{\n    margin-top: 3rem;\n    margin-bottom: 3rem;\n    padding: 10px;\n    border: 0;\n    color: white;\n    background: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
